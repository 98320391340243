<template>
  <div class="mall-goods-shop">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.kw"
          placeholder="请输入商品名称搜索"
          @input="kwChange"
          clearable
          class="mr15"
        />
        <el-select
          v-model="tableParams.goodsType"
          placeholder="请选择商品分类"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in typeList"
            :key="item.goodsTypeId"
            :label="item.goodsTypeName"
            :value="item.goodsTypeId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.sellStatus"
          placeholder="请选择商品状态"
          @change="reload"
          clearable
        >
          <el-option
            v-for="item in ObjToOpt(goodsStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增商品</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="商品缩略图" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 0.28rem; height: 0.28rem"
            :src="scope.row.imageUrl"
            :preview-src-list="[scope.row.imageUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品库存" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.inventory || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品分类" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="实际销量 / 虚拟销量" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.salesVolume || "0" }}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{{ scope.row.salesVolumeV || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="销售价格 / 划线价格" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.salesPrice || "0" }}</span>
          <span>&nbsp;/&nbsp;</span>
          <del>￥{{ scope.row.originalPrice || "0" }}</del>
        </template>
      </el-table-column>
      <el-table-column label="商品状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsStatusName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onUp(scope.row)"
            v-if="!scope.row.goodsStatus"
            >上架</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onDown(scope.row)"
            v-else
            >下架</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onReplenish(scope.row)"
            >补货</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onSend(scope.row)"
            v-if="scope.row.goodsStatus && scope.row.inventory > 0"
            >商品码派发</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.pageSize"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog
      :title="editTitle"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="30%"
    >
      <!-- 表单提交 -->
      <el-form
        :model="params"
        :rules="rules"
        ref="form-ref"
        label-width="100px"
      >
        <section class="form-main">
          <el-form-item label="商品" prop="shopGoodsId">
            <el-select
              v-model="params.shopGoodsId"
              placeholder="请选择商品"
              clearable
              :disabled="params.orderGoodsId"
            >
              <el-option
                v-for="item in goodsList"
                :key="item.shopGoodsId"
                :label="item.goodsName"
                :value="item.shopGoodsId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品库存" prop="inventory">
            <el-input
              v-model.trim="params.inventory"
              autocomplete="off"
              maxlength="5"
              placeholder="请填写商品库存"
              oninput="value=value.replace(/[^\d]/g,'')"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="商品分类" prop="goodsType">
            <el-select
              v-model="params.goodsType"
              placeholder="请选择商品分类"
              clearable
            >
              <el-option
                v-for="item in typeList"
                :key="item.goodsTypeId"
                :label="item.goodsTypeName"
                :value="item.goodsTypeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')"
            >保存</el-button
          >
        </section>
      </el-form>
    </el-dialog>
    <!-- 补货弹框 -->
    <el-dialog
      title="商品补货"
      :visible.sync="replenishShow"
      :close-on-click-modal="false"
      append-to-body
      width="30%"
    >
      <el-form
        :model="replenishParams"
        :rules="rules"
        ref="form-replenish"
        label-width="100px"
      >
        <section class="form-main">
          <el-form-item label="补货数量" prop="inventory">
            <el-input
              v-model.trim="replenishParams.inventory"
              autocomplete="off"
              maxlength="5"
              placeholder="请填写补货数量"
              oninput="value=value.replace(/[^\d]/g,'')"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="当前库存">
            {{ replenishParams.inventoryNow }}
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onReplenishSubmit('form-replenish')"
            >保存</el-button
          >
        </section>
      </el-form>
    </el-dialog>
    <!--商品码派发-->
    <el-dialog
      title="商品码派发"
      :visible.sync="sendShow"
      :close-on-click-modal="false"
      append-to-body
      width="500px"
    >
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <div style="margin: 15px 0"></div>
      <el-checkbox-group
        v-model="checkedPeople"
        @change="handleCheckedPeopleChange"
      >
        <el-checkbox
          v-for="people in peopleList"
          :label="people.advanceId"
          :key="people.advanceId"
          >{{ people.nickName }}</el-checkbox
        >
      </el-checkbox-group>
      <section class="form-footer">
        <el-button type="primary" @click="goConfirmSend()">确定</el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMallGoodsShop,
  getShopGoodsList,
  getGoodsTypeList,
  getGoodsShopAdd,
  getGoodsShopEdit,
  getGoodsShopUp,
  getGoodsShopReplenish,
  goExportQrCode,
  goCheckExportQrCode,
} from "@/api/mall/mall";
import { getPeopleList } from "@/api/serve/serve";
import { ObjToOpt } from "@/utils/utils";
import { goodsStatusObj } from "@/db/objs";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      ObjToOpt,
      goodsStatusObj,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "", //查询关键字
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        goodsType: "", //商品分类
      },
      // 商品分类列表
      typeList: [],
      goodsList: [],
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "", //弹框标题
      // from表单
      rules,
      params: {
        shopGoodsId: "",
        inventory: "",
        goodsType: "",
      },
      // 补货弹框
      replenishShow: false, //弹框开关
      replenishParams: {
        inventory: "",
        inventoryNow: "",
      },
      // 商品码
      sendShow: false,
      isIndeterminate: false,
      checkAll: false,
      checkedPeople: [],
      peopleList: [],

      orderGoodsId: "",
      goodsName: "",
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getMallGoodsShop();
    this.getGoodsTypeList();
    this.getPeopleList();
  },
  methods: {
    // 推销码导出检查
    goCheckExportQrCode() {
      let params = {
        advanceIdSet: this.checkedPeople,
        orderGoodsId: this.orderGoodsId,
        shopId: this.tableParams.shopId,
      };
      goCheckExportQrCode(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.sendShow = false;

          this.goExportQrCode();
        }
      });
    },
    // 确定导出
    goConfirmSend() {
      if (this.checkedPeople.length == 0) {
        this.$message({
          message: "请至少选择一位推销员",
          type: "error",
        });
      } else {
        this.goCheckExportQrCode();
      }
    },
    // 导出二维码
    goExportQrCode() {
      let params = {
        advanceIdSet: this.checkedPeople,
        orderGoodsId: this.orderGoodsId,
        shopId: this.tableParams.shopId,
      };
      goExportQrCode(params, this.goodsName, true);
    },
    //  获取运营人员列表
    getPeopleList() {
      let data = {
        page: 1,
        pageSize: 0,
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
      };
      getPeopleList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.peopleList = res.data.list;
        }
      });
    },
    // 全选
    handleCheckAllChange(val) {
      if (val) {
        this.peopleList.forEach((item) => {
          this.checkedPeople.push(item.advanceId);
        });
      } else {
        this.checkedPeople = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedPeopleChange(value) {

      let checkedCount = value.length;
      if(checkedCount == this.checkedPeople.length){
        this.checkAll = true;
      }
      if(checkedCount == 0){
        this.checkAll = false;

      }
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.peopleList.length;
    },
    // 打开商品码派发
    onSend(row) {
      this.orderGoodsId = row.orderGoodsId;
      this.goodsName = row.goodsName;
      this.checkedPeople = []
      this.sendShow = true;
    },
    // 【请求】点单商品列表
    getMallGoodsShop() {
      let data = this.tableParams;
      getMallGoodsShop(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】点单商品列表
    getShopGoodsList() {
      let data = this.params;
      getShopGoodsList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.goodsList = res.data;
          }
          this.$forceUpdate();
        }
      });
    },

    // 【请求】点单商品分类列表
    getGoodsTypeList() {
      let data = this.tableParams;
      getGoodsTypeList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.typeList = res.data;
        }
      });
    },

    // 【请求】新增点单商品
    getGoodsShopAdd() {
      let data = this.params;
      getGoodsShopAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.reload(); // 【请求】表格数据
        }
      });
    },

    // 【请求】编辑点单商品
    getGoodsShopEdit() {
      let data = this.params;
      getGoodsShopEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getMallGoodsShop(); // 【请求】表格数据
        }
      });
    },

    // 【请求】补货点单商品
    getGoodsShopReplenish() {
      let data = this.replenishParams;
      getGoodsShopReplenish(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.replenishShow = false;
          this.getMallGoodsShop(); // 【请求】表格数据
        }
      });
    },

    // 【请求】上下架点单商品
    getGoodsShopUp() {
      let data = this.params;
      getGoodsShopUp(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getMallGoodsShop();
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getMallGoodsShop();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getMallGoodsShop();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增商品";
      this.params = {
        shopId: this.tableParams.shopId,
        merchantId: this.tableParams.merchantId,
        shopGoodsId: "",
        inventory: "",
        goodsType: "",
      };
      this.getShopGoodsList();
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑商品";
      this.params = JSON.parse(JSON.stringify(row));
      this.getShopGoodsList();
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】上架
    onUp(row) {
      this.params = JSON.parse(JSON.stringify(row));
      this.params.goodsStatus = 1;
      this.$confirm("你确定要上架该商品吗?", "商品上架", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.getGoodsShopUp();
      });
    },

    // 【监听】上架
    onDown(row) {
      this.params = JSON.parse(JSON.stringify(row));
      this.params.goodsStatus = 0;
      this.$confirm("你确定要下架该商品吗?", "商品下架", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.getGoodsShopUp();
      });
    },

    // 【监听】补货
    onReplenish(row) {
      this.replenishShow = true;
      this.replenishParams = JSON.parse(JSON.stringify(row));
      this.replenishParams.inventoryNow = row.inventory;
      this.replenishParams.inventory = "";
      this.$nextTick(() => {
        this.$refs["form-replenish"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.orderGoodsId) {
            this.getGoodsShopEdit();
          } else {
            this.getGoodsShopAdd();
          }
        } else {
          return false;
        }
      });
    },

    // 【监听】补货表单提交
    onReplenishSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getGoodsShopReplenish();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mall-goods-shop {
  padding: 0.15rem;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
.export {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.export-success {
  .el-icon-check {
    font-size: 0.48rem;
  }
}
</style>